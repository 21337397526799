// 质检记录
<template>
  <div class="menu">
    <div class="screening">
      <el-dialog title="查询" v-model="theQueryDialog" :close-on-click-modal="false" :destroy-on-close="true"
        width="600px">
        <el-form label-position="left" label-width="100px" size="small" :model="searchFrom" ref="upform">
          <el-form-item label="主叫前缀">
            <el-input v-model="searchFrom.telAPrefix" class="input-searchFrom-width" placeholder="请输入需要查询的手机号"
              size="mini"></el-input>
          </el-form-item>
          <el-form-item label="被叫前缀">
            <el-input v-model="searchFrom.telBPrefix " class="input-searchFrom-width" placeholder="请输入需要查询的手机号"
              size="mini"></el-input>
          </el-form-item>
          <!-- <el-form-item label="主叫号码">
            <el-input v-model="searchFrom.telA" class="input-searchFrom-width" placeholder="请输入需要查询的手机号" size="mini">
            </el-input>
          </el-form-item> -->
          <!-- <el-form-item label="被叫号码">
            <el-input v-model="searchFrom.telB" class="input-searchFrom-width" placeholder="请输入需要查询的手机号" size="mini">
            </el-input>
          </el-form-item> -->
          <!--<el-form-item label="识别内容">
            <el-input v-model="searchFrom.translatedContent" class="input-searchFrom-width" placeholder="请输入需要查询的识别内容"
              size="mini">
            </el-input>
          </el-form-item>-->
          <!-- <el-form-item label="行业">
            <el-input v-model="searchFrom.industry" class="input-searchFrom-width" placeholder="请输入需要查询的行业" size="mini">
            </el-input>
          </el-form-item> -->
          <el-form-item label="识别结果">
            <div class="input-searchFrom-width">
              <el-select v-model="searchFrom.translatedResult" placeholder="请选择" style="width: 100%" clearable
                size="mini">
                <el-option v-for="(item, index) in [{name:'全部',id:''},{name:'成功',id:0},{name:'失败',id:1}]" :key="index"
                  :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          
          <!-- <el-form-item label="创建时间">
            <el-date-picker v-model="valueTime" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期"
              clearable value-format="YYYY-MM-DD HH:mm:ss" size="mini" :default-time="defaultTime">
            </el-date-picker>
          </el-form-item> -->
          <!--<el-form-item label="命中关键词">
            <el-input v-model="searchFrom.keyword" class="input-searchFrom-width" placeholder="请输入需要查询的命中关键词"
              size="mini"></el-input>
          </el-form-item>-->
          <!--<el-form-item label="质检类型">
            <div class="input-searchFrom-width">
              <el-select v-model="searchFrom.hitType" placeholder="请选择质检类型" style="width: 100%" clearable filterable>
                <el-option v-for="(item, index) in voiceName" :key="index" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </div>
          </el-form-item>-->
          <el-form-item v-if="userType=='system'" label="用户">
            <div class="input-searchFrom-width">
              <el-select v-model="searchFrom.uid" placeholder="请选择用户" style="width: 100%" clearable filterable>
                <el-option v-for="(item, index) in user_list" :key="index" :label="item.nickname" :value="item.uid">
                  <div class="flex-nowrap-space-between">
                    <div :class="item.status == 1?'no-red':''">
                      <span>{{ item.nickname }}</span>
                      <span class="ml-5">(Id:{{ item.uid }})</span>
                    </div>
                    <div v-if="item.status == 1" class="no-red">禁</div>
                  </div>
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="通话时间">
            <div class="input-searchFrom-width">
              <el-select v-model="durationVal" placeholder="请选择通话时间" style="width: 100%" @change="changeValues"
                clearable filterable>
                <el-option v-for="(item, index) in [
                                    {
                                        name:'所有时间',
                                        val:0
                                    },{
                                        name:'0~10秒',
                                        val:1
                                    },{
                                        name:'10~60秒',
                                        val:2
                                    },{
                                        name:'60~90秒',
                                        val:3
                                    },{
                                        name:'90~120秒',
                                        val:4
                                    },{
                                        name:'大于120秒',
                                        val:5
                                    },
                                ]" :key="index" :label="item.name" :value="item.val">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button class="cancel" plain @click="reset" round>
                重置
              </el-button>
              <el-button class="confirmAdd" @click="onSubmit" round>
                查询
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <el-row>
      <el-col :span="2">
        <div class="title-name">
          数据列表
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="4">
        <el-input v-model="searchFrom.telAPrefix" clearable size="mini">
          <template #prepend>主叫前缀</template>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-input v-model="searchFrom.telA" clearable size="mini">
          <template #prepend>主叫号码</template>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-input v-model="searchFrom.telBPrefix" clearable size="mini">
          <template #prepend>被叫前缀</template>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-input v-model="searchFrom.telB" clearable size="mini">
          <template #prepend>被叫号码</template>
        </el-input>
      </el-col>

      
      <el-col :span="4">
        <div class="el-input el-input--mini el-input-group el-input-group--prepend el-input--prefix el-input--suffix">
          <div class="el-input-group__prepend">审核状态</div>
          <el-select v-model="searchFrom.auditStatus" placeholder="请选择" clearable size="mini">
            <el-option
              v-for="(item, index) in [{name:'全部',id:''},{name:'已审核',id:1},{name:'违规',id:2},{name:'误判',id:3},{name:'复审',id:4}]"
              :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </el-col>
         <el-col :span="4">
        <div class="el-input el-input--mini el-input-group el-input-group--prepend el-input--prefix el-input--suffix">
          <div class="el-input-group__prepend">风险等级</div>
          <el-select v-model="searchFrom.risk" placeholder="请选择" style="" clearable size="mini">
             
  <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="4">
        <el-input v-model="searchFrom.industry" clearable size="mini">
          <template #prepend>行业</template>
        </el-input>
      </el-col>
      <el-col :span="8">
        <div class="el-input el-input--mini el-input-group el-input-group--prepend el-input--prefix el-input--suffix">
          <div class="el-input-group__prepend">条件选择</div>
          <el-select :style="{width:'110px'}" v-model="isIncludes" placeholder="请选择"  size="mini">
            <el-option v-for="(item, index) in [{name:'包含',id:1},{name:'不包含',id:2}]" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <div class="el-input-group__prepend">命中类型</div>
          <el-select
           v-model="choiceHitType" 
           placeholder="请选择命中类型"
            clearable filterable 
            allow-create
            size="mini">
            <el-option v-for="(item, index) in voiceName" :key="index" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="4">
        <el-input v-model="searchFrom.translatedContent" clearable size="mini">
          <template #prepend>关键字</template>
        </el-input>
      </el-col>
      <el-col :span="4">
        <div class="el-input el-input--mini el-input-group el-input-group--prepend el-input--prefix el-input--suffix">
          <div class="el-input-group__prepend">是否命中</div>
          <el-select :style="{width:'110px'}" v-model="searchFrom.hitTypeStatus" placeholder="请选择"  size="mini">
            <el-option v-for="(item, index) in [{name:'命中',id:1},{name:'未命中',id:0}]" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="4">
        <el-input v-model="searchFrom.callerTranslatedContent" clearable size="mini">
          <template #prepend>主叫关键字</template>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-input v-model="searchFrom.calleeTranslatedContent" clearable size="mini">
          <template #prepend>被叫关键字</template>
        </el-input>
      </el-col>
      <el-col :span="8">
        <div class="el-input el-input--mini el-input-group el-input-group--prepend el-input--prefix el-input--suffix">
          <div class="el-input-group__prepend">质检日期</div>
          <div>
            <el-date-picker v-model="valueTime" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期"
              value-format="YYYY-MM-DD HH:mm:ss" :default-time="defaultTime" size="mini" :clearable="false">
            </el-date-picker>
          </div>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="el-input el-input--mini el-input-group el-input-group--prepend el-input--prefix el-input--suffix">
          <div class="el-input-group__prepend">识别结果</div>
          <el-select v-model="searchFrom.translatedResult" placeholder="请选择" style="" clearable size="mini">
            <el-option v-for="(item, index) in [{name:'全部',id:''},{name:'成功',id:0},{name:'失败',id:1}]" :key="index"
              :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </el-col>
     
      <el-col :span="4">
        <div class="searchArea">
          <el-button icon="el-icon-search" class="button-el" size="medium" @click="onSubmit">查询</el-button>
          <!-- <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询</el-button>-->
          <div class="font-refresh-out flex-nowrap-center-center ml-10" @click="reset(1)">
            <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh" />
            <i v-else class="el-icon-loading loding-color"></i>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-table class="eltable" highlight-current-row v-loading="loading" ref="multipleTable" :data="tableData"
            tooltip-effect="dark" style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
            row-key="id" size="mini" :row-style="rowstyle" :cell-style="{padding:'0px'}" default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" @row-click="openDiv"
            :style="{cursor:'pointer'}" @selection-change="handleSelectionChange">
            <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
            <el-table-column label="昵称" v-if="userType=='system'" width="150">
              <template #default="scope">
                {{ scope.row.nickname == null|| scope.row.nickname == ''?'-': scope.row.nickname}}
              </template>
            </el-table-column>
            <el-table-column label="行业类型" width="100">
              <template #default="scope">{{ scope.row.industry == null?'-':scope.row.industry}}</template>
            </el-table-column>
            <!-- <el-table-column label="前缀" width="130">
              <template #default="scope">
                <p>{{ scope.row.telAPrefix == null|| scope.row.telAPrefix == ''?'-': scope.row.telAPrefix}}</p>
                <p>{{ scope.row.telBPrefix == null|| scope.row.telX == ''?'-': scope.row.telBPrefix}}</p>
              </template>
            </el-table-column> -->
            <el-table-column label="主叫/被叫" width="250">
              <template #default="scope">
                <p><b>主叫: </b> {{scope.row.telAPrefix?scope.row.telAPrefix:' '}}{{scope.row.telA ? scope.row.telA :' '}}</p>
                <p><b>被叫: </b>{{ scope.row.telBPrefix?scope.row.telBPrefix:' '}}{{scope.row.telB ? scope.row.telB :' '}}</p>
              </template>
            </el-table-column>
            <!--<el-table-column label="命中状态" width="100">
              <template
                #default="scope">{{ scope.row.hitTypeStatus == null|| scope.row.hitTypeStatus == ''?'-':(scope.row.hitTypeStatus == 0?'未命中':'命中' )}}</template>
            </el-table-column>-->
            <!-- <el-table-column label="命中类型">
              <template
                #default="scope">{{ scope.row.hitType == null|| scope.row.hitType == ''?'-': scope.row.hitType}}</template>
            </el-table-column> -->
            <el-table-column label="命中类型" width="200">
              <template #default="scope">
                <el-tooltip placement="top">
                  <template #content>
                    <p class="ellipsis">
                      {{ scope.row.hitType == null|| scope.row.hitType == ''?'-': scope.row.hitType}}
                    </p>
                  </template>
                  <div>
                    <p class="ellipsis">
                      {{ scope.row.hitType == null|| scope.row.hitType == ''?'-': scope.row.hitType}}
                    </p>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>

             <el-table-column label="风险等级" width="100">
              <template #default="scope">                {{ scope.row.riskText === null ? "-" : scope.row.riskText }}
</template>
            </el-table-column>
            <el-table-column label="关键字">
              <template #default="scope">
                <el-tag size="small" class="keywords" effect="dark" type="danger"
                  v-for="(item, i) in scope.row.hitTypeDesc?.split(',')" :key="i">
                  {{ item }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="话术/语音内容">
              <template #default="scope">
                <el-popover placement="left" :width="500" trigger="hover" effect="dark">
                  <template #reference>
                    <p class="ellipsis-line3" @click="quickConfirm(scope.row)" v-html="scope.row.desc.join('')" />
                  </template>
                  <el-row class="voice-text">
                    <el-col :span="8" v-if="scope.row.hitTypeDesc && scope.row.hitTypeDesc !== '-'">
                      <el-tag size="small" effect="light" round v-for="(item, i) in scope.row.hitTypeDesc.split(',')"
                        :key="i" class="keywords" @click="scrollTo($event)">
                        {{ item }}
                      </el-tag>
                    </el-col>
                    <el-col :span="16">
                      <el-scrollbar @mouseenter="quickConfirm(scope.row)" class="voice-text-tooltip" max-height="300px">
                        <p v-for="(item, i) in scope.row.desc" :key="i">
                          <span class="sentence" v-html="item" />
                        </p>
                      </el-scrollbar>
                    </el-col>
                  </el-row>
                </el-popover>
                <p v-if="!scope.row.translatedContent">-</p>
              </template>
            </el-table-column>
            <!--<el-table-column label="语音识别" width="100">
              <template
                #default="scope">{{ scope.row.translatedResult == null?'-':(scope.row.translatedResult == 0?'成功':'失败')}}</template>
            </el-table-column>-->
            <!--<el-table-column label=费用 width="100">
              <template
                #default="scope">{{ scope.row.price == null|| scope.row.price == ''?'-': scope.row.price}}</template>
            </el-table-column>-->
            <el-table-column label="质检日期" width="200">
              <template
                #default="scope">{{ scope.row.createTime == null|| scope.row.createTime == ''?'-': scope.row.createTime}}</template>
            </el-table-column>
            <!-- <el-table-column label="对话" width="80">
              <template #default="scope">
                <el-tag :class="readId.indexOf(scope.row.id) == -1?'unRead':'Read'" class="talks"
                  @click="openDiv(scope.row)">
                  <icon-font type="icon-duihua" class="talkcolor" />
                </el-tag>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="pager">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        background :page-sizes="[20, 50, 100, 200]" :page-size="searchFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 对话详情模态框 -->
    <el-dialog v-model="centerDialogVisible" title="对话详情" width="40%" :destroy-on-close="true">
      <div>
        <div class="record">
          <audio class="recordSon" :src="recordUrl" controls="controls"></audio>
        </div>
        <div class="audit">
          <el-radio-group v-model="auditRow.auditStatus" @change="auditCommit">
            <el-radio :label="1" border>已审核</el-radio>
            <el-radio :label="2" border>违规</el-radio>
            <el-radio :label="3" border>误判</el-radio>
            <el-radio :label="4" border>复审</el-radio>
          </el-radio-group>
        </div>
        <div class="hitType-box">{{ hitType == null?'未命中类型':hitType }}</div>
        <div class="dialogue">
          <div v-for="(item,i) in dialogueList" :key="i" :class="item?.channel_id == 0?'item-left':'item-right'">
            <div>
              <!-- <div :class="item?.channel_id == 0?'title-left':'title-right'" class="title-item">{{ item?.channel_id == 0?'主叫：':'被叫：' }}</div> -->
              <div :class="item?.channel_id == 0?'title-left':'title-right'" class="title-item"></div>
              <div class="item-text" :style="item?.channel_id == 0?'background: #fff;':'background: #95ec69;'"
                v-html="$filter.mateKeyword(item?.text,hitTypeDesc)"></div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="centerDialogVisible = false">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import voiceCheck from '@/api/open/voiceCheck/voiceCheck'
import voiceType from '@/api/open/voice/voiceType'
import { getDate } from '@/utils/timeToChoose.js'
import { IconFont } from '@/utils/iconfont'
import { ElMessage } from 'element-plus'
import userts from '@/api/web/user'
import store from '@/store/index'
export default {
  components: {
    IconFont,
  },
  data() {
    return {
        options: [{
          value: null,
          label: '全部'
        }, {
          value: 3,
          label: '高'
        }, {
          value: 2,
          label: '中'
        }, 
        {
          value: 1,
          label: '低'
        },{
          value: 0,
          label: '无'
        }
          ],
      userType: store.state.userType,
      isIncludes:null,//包含/不包含
      choiceHitType:'',//选择命中类型
      durationVal: 0,
      tableHeight: '',
      readId: [],
      user_list: [], // 用户的集合
      centerDialogVisible: false,
      searchFrom: {
        page: 1,
        pageSize: 10,
        translatedResult: 0,
        telA: '',
        telB: '',
        telAPrefix: '',
        telBPrefix: '',
        createTimeBegin: '',
        createTimeEnd: '',
        translatedContent: '', //识别内容
        industry: '', // 行业
        hitTypeStatus : 1, // 命中
        risk:'',
        // notHitType: '', // 不包含
        // hitType: '', // 包含
      },
      total: 0,
      currentPage: 1, //分页
      multipleSelection: [], //选中集合
      valueTime: [], //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ], //默认时间 '12:00:00', '08:00:00'
      activeNames: [], //折叠面板
      tableData: [], //菜单表
      // loding 状态启用
      loading: true,
      dialogueList: [],
      recordUrl: '',
      hitTypeDesc: [],
      hitType: '',
      auditRow: null,
      theQueryDialog: false,
      voiceName: [],
    }
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight
  },
  mounted() {
    this.valueTime = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
    this.getDataList(true)
    this.getVoiceName()
    if (this.userType == 'system') {
      this.getVoiceUser()
    }
  },
  methods: {
    // 记录行更换背景色
    rowstyle({ row, rowIndex }) {
      let stylejson = {}
      stylejson.height = '50px'
      switch (row.auditStatus) {
        case 1:
          stylejson.background = '#e4fcd9'
          break
        case 2:
          stylejson.background = '#f8b8b6'
          break
        case 3:
          stylejson.background = '#cddef8'
          break
        case 4:
          stylejson.background = '#faead4'
          break
        default:
          stylejson.background = '#FFFFFF'
      }
      return stylejson
    },
    // 滚动到指定质检关键字
    // 暂未实现同个关键字多处位置间的切换
    scrollTo(e) {
      this.$nextTick(() => {
        let list =
          e.currentTarget.parentElement.nextElementSibling.querySelectorAll(
            '.keyword-tag'
          )
        for (let i = 0; i < list.length; i++) {
          if (list[i].innerText === e.currentTarget.innerText) {
            list[i].scrollIntoView()
          }
        }
      })
    },
    // 将一条质检记录标记为已读(暂未刷新统计数)
    quickConfirm(row) {
      this.rowstyle({ row, rowIndex: null })
      if (this.readId.indexOf(row.id) == -1) {
        this.readId.push(row.id)
      }
      if (row.read == 1) {
        return
      }
      {
        voiceCheck
          .vosCheck({
            id: row.id,
          })
          .then((res) => {
            if (res.code == 200) {
              row.read = 1
            }
          })
      }
    },
    // 根据后端返回的语音文本json,获取其中的句子部分,并高亮显示关键字
    getVoiceText(item) {
      let keywords = item.hitTypeDesc
      if (item.translatedContent == null) {
        return ['-']
      }
      let sentences = []
      try {
        sentences = JSON.parse(item.translatedContent)?.payload?.sentences
      } catch (err) {
        // 因查看对话会改变translatedContent值,这里先通过捕获异常处理
        sentences = item.translatedContent
      }
      if (keywords !== '' && keywords != null) {
        // 如果包含关键字,对关键字做高亮处理
        keywords.split(',').forEach((keyword) => {
          sentences.map((sentence) => {
            if (sentence.text.search(keyword) !== -1) {
              sentence.text = sentence.text.replace(
                keyword,
                '<b class="keyword-tag" style="color:#ff0000;">' +
                  keyword +
                  '</b>'
              )
              return sentence
            }
          })
        })
      }
      if (sentences == undefined || sentences.length === 0) {
        return ['-']
      }
      return sentences.map((sentence) => sentence.text)
    },
    // 查询
    onSubmit() {
      this.theQueryDialog = false
      this.getDataList(true)
    },
    changeValues(val) {
      this.searchFrom.durationBegin = this.durationToVal(val)[0]
      this.searchFrom.durationEnd = this.durationToVal(val)[1]
    },
    durationToVal(val) {
      if (val == 0) {
        return []
      }
      if (val == 1) {
        return [0, 10]
      }
      if (val == 2) {
        return [10, 60]
      }
      if (val == 3) {
        return [60, 90]
      }
      if (val == 4) {
        return [90, 120]
      }
      if (val == 5) {
        return [120, '']
      }
    },
    // 重置
    reset(kind) {
      this.searchFrom = {
        page: this.currentPage,
        pageSize: 10,
        translatedResult: null,
      }
      this.durationVal = 0
      this.valueTime = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
      if (kind == 1) {
        this.searchFrom.translatedResult = 0
        this.theQueryDialog = false
        this.getDataList(true)
      }
    },
    // 语音质检类型下拉
    getVoiceName() {
      voiceType.voiceName({}).then((res) => {
        this.voiceName = res.data
      })
    },
    getVoiceUser() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data
      })
    },
    // 获取数据列表
    getDataList(type) {
      this.loading = type
      if (this.valueTime != null) {
        this.searchFrom.createTimeBegin = this.valueTime[0]
        this.searchFrom.createTimeEnd = this.valueTime[1]
      } else {
        this.searchFrom.createTimeBegin = ''
        this.searchFrom.createTimeEnd = ''
      }
      if(this.isIncludes==1){
        this.searchFrom.hitType = this.choiceHitType
        delete this.searchFrom.notHitType
      }else{
        this.searchFrom.notHitType = this.choiceHitType
        delete this.searchFrom.hitType
      }
      voiceCheck
        .voschecks({
          ...this.searchFrom,
        })
        .then((res) => {
          this.tableData = res.data.records
          this.tableData.forEach((item) => {
            item.desc = this.getVoiceText(item)
          })
          this.total = res.data.total
          this.loading = false
        })
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.searchFrom.pageSize = val
      this.getDataList(true)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.searchFrom.page = val
      this.currentPage = val
      this.getDataList(true)
    },
    // 折叠面板
    handleChange(val) {
      console.log(val)
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 批量删除
    deleteData() {
      console.log(this.multipleSelection)
    },
    // 提交审核状态
    auditCommit(auditStatus) {
      voiceCheck
        .vosAudit({
          id: this.auditRow.id,
          auditStatus: auditStatus,
        })
        .then((res) => {
          if (res.code == 200) {
            this.auditRow.auditStatus = auditStatus
          }
        })
      this.auditRow.auditStatus = auditStatus
    },
    // 打开对话
    openDiv(row) {
      if (this.readId.indexOf(row.id) == -1) {
        this.readId.push(row.id)
      }
      if (row.hitTypeDesc != null) {
        this.hitTypeDesc = row.hitTypeDesc.split(',')
      }
      voiceCheck
        .getByCheckId({
          checkId: row.id,
        })
        .then((res) => {
          if (res.data == undefined) {
            ElMessage({
              showClose: true,
              message: '暂无质检记录。',
              type: 'warning',
            })
          } else if (res.data.translatedContent == null) {
            ElMessage({
              showClose: true,
              message: '暂无质检记录。',
              type: 'warning',
            })
          } else {
            this.recordUrl = row.fileUrl
            this.hitType = row.hitType
            this.auditRow = row
            this.dialogueList = JSON.parse(
              res.data.translatedContent
            ).payload?.sentences
            this.centerDialogVisible = true
            if (row.auditStatus == 0) {
              this.auditCommit(1)
            } else {
              return
            }
          }
        })
      // this.quickConfirm(row)
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 1.125rem;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.center-body {
  margin: 0 1.875rem;
}
.deletetable {
  color: #ff845b;
  background: #ffe4db;
}
.deletetable-other {
  margin-right: 1.25rem;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0 0.9375rem;
}
.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}
.pager {
  display: flex;
  justify-content: flex-end;
}
.pass {
  color: #67c23a;
}
.unpass {
  color: #f56c6c;
}
.priceColor {
  color: #409eff;
}
.talks {
  cursor: pointer;
}
.record {
  height: 5rem;
}
.dialogue {
  overflow-y: overlay;
  height: 25rem;
  padding: 0.425rem 1.5rem;
  background: #f5f5f5;
}
.recordSon {
  width: 100%;
}
.item-text {
  display: inline-block;
  padding: 0.375rem;
  border-radius: 0.375rem;
  color: #000;
}
.item-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.item-right {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.title-left {
  text-align: left;
}
.title-right {
  text-align: right;
}
.title-item {
  padding: 0.375rem;
}
.talkcolor {
  font-size: 1rem;
  color: #fff;
}
.Read {
  background: #abadbb;
}
.keywords {
  margin-right: 5px;
  margin-bottom: 5px;
}
.sentence {
  background-color: #95ec69;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 7px;
  color: #000000;
}
.audit {
  display: flex;
  display: -webkit-flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis-line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  cursor: pointer;
}
tr {
  pointer-events: none;
}
::v-deep(.el-table tbody tr:hover > td) {
  background-color: transparent !important;
}
.searchArea {
  display: flex;
  align-items: center;
  justify-content: right;
}
::v-deep(.el-input-group--prepend
    .el-select
    .el-input.is-focus
    .el-input__inner) {
  border-color: var(--el-select-input-focus-border-color) !important;
}
::v-deep(.el-select-dropdown__item) {
  font-size: 0.75rem !important;
}
::v-deep(.el-table__body tr.current-row > td) {
  background: unset !important;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}
.eltable {
  overflow-y: overlay;
}
</style>